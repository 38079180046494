<template>
  <div class="g col-12 c">
    <div class="card">
        <div class="card-body">
            <button class="btn btn-warning btn-sm" @click="print()">
                <i class="fa fa-print"></i>
                طباعة
            </button>
            &nbsp;
            <button class="btn btn-success btn-sm" @click="excel()">
                <i class="fa fa-download"></i>
                تصدير
            </button>
            &nbsp;
            <button class="btn btn-primary btn-sm" @click="send()">
                <i class="fa fa-paper-plane"></i>
                ارسال تنبيه لمن لم يدخل
            </button>
            &nbsp;
            <select v-model="show">
                <option value="all">عرض الكل</option>
                <option value="1">من قام بالدخول</option>
                <option value="2">من لم يقم بالدخول</option>
            </select>
            &nbsp;
            <select v-model="classroom">
                <option value="all">عرض كل الفصول</option>
                <option v-for="o in classrooms" :key="o" :value="o">{{ o }}</option>
            </select>
            <br><br>
            <table id="headerTable" class="table table-hover table-bordered table-sm">
                <h4 id="datex" class="dnone"></h4>
              <thead>
                <th>
                  م
                </th>
                <th>
                 رقم الهوية
                </th>
                <th>
                  اسم الطالب
                </th>
                <th>
                  اسم المستخدم
                </th>
                <th>
                  الجوال
                </th>
                <th>
                  الفصل
                </th>
                <th>
                  آخر دخول
                </th>
              </thead>
              <tbody>
                <template v-for="(student, c) in students">
                  <tr :key="c" v-if="(show=='all'||show=='1'&&dates[student.number]||show=='2'&&!dates[student.number]) && (classroom == 'all' || classroom == student.classroom)">
                      <td>{{ c + 1 }}</td>
                      <td>{{ student.number }}</td>
                      <td>{{ student.name }}</td>
                      <td>{{ student.email }}</td>
                      <td>{{ student.phone }}</td>
                      <td>{{ student.classroom }}</td>
                      <td>
                        <span v-if="dates[student.number]">
                            {{ dates[student.number] }}
                        </span>
                        <strong v-if="!dates[student.number]" class="text-danger">
                            لم يتم الدخول
                        </strong>
                      </td>
                  </tr>
                </template>
              </tbody>
            </table>
        </div>
    </div><button
      class="dnone"
      id="notjoinstudentsalert" @click="messageChanged()"
      v-b-modal.modal-teachers-lessons-send2ss
    ></button>
    <b-modal id="modal-teachers-lessons-send2ss" size="lg" title="ارسال تنبيه" hide-footer>
      <div class="col-12 text-center g">
            <div class="alert alert-success text-center g" v-if="view_archive">
                <img src="/files/done.gif" style="border-radius: 50%">
                <br>
                <br>
                تم استلام الرسائل بنجاح وجاري ارسالها الآن٫<br>
                يمكنك متابعة الارسال <a :href="`/archive?school_id=${school_id}`" target="_blank"><strong><u>من هنا</u></strong></a>
            </div>
            <template v-if="!loading && !view_archive">
                <button class="btn btn-lg btn-primary" v-if="selected == 'sms' || selected == 'whatsapp-api'" @click="sendNow(selected)">
                    <i class="fa fa-check-square"></i>
                    ارسال الآن
                </button>
                <div class="col-12 text-center alert alert-success" v-if="selected == 'whatsapp'" id='remove-me-wa'>
                    <h4>
                        لتتمكن من ارسائل رسائل الواتسآب، يجب عليك تحميل أداة الخدمات الاضافية من سوق كروم.
                        <br><br>
                        <a href="https://bit.ly/3yAC50a" target="_blank" class="btn btn-success">
                            <i class="fa fa-download"></i>
                            إضغط هنا لتثبيت الاداة
                        </a>
                        <br>
                        وبعد تثبيتها قم بإعادة تحديث هذه الصفحة.
                    </h4>
                </div>
                <button class="btn btn-lg btn-primary" v-if="selected == 'whatsapp'" @click="sendNow(selected)" id="brmja-send">
                    <i class="fa fa-check-square"></i>
                    ارسال الآن
                </button>
            </template>
            <template v-if="loading && !view_archive">
                <button class="btn btn-lg btn-primary disabled">
                    جاري الإرسال...
                </button>
            </template>
        </div>
        <div class="form-group">
          <label for="">محتوى الرسالة</label>
          <textarea class="form-control" v-model="message" @keyup="messageChanged()" rows="5"></textarea>
        </div>
        <div class="col-12 table-responsive">
            <table class="table table-sm table-hover" id="brmja-messages-table">
                <thead>
                    <th>
                        الجوال
                    </th>
                    <th>
                        الطالب
                    </th>
                    <th>
                        الرسالة
                    </th>
                </thead>
                <tbody>
                    <tr v-for="(teacher, c) in teachers" :key="c">
                        <td>{{ teacher.phone }}</td>
                        <td>{{ teacher.name }}</td>
                        <td><textarea class='form-control msg' readonly v-model="teacher.message"></textarea></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </b-modal>
  </div>
</template>

<script>
var XLSX = require("xlsx");
import { VBModal, BModal, BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
    data(){
        return {
            school_id: window.location.href.split("school_id=")[1].split("&")[0],
            message: `الطالب {name}
لم يتم الدخول لمنصة مدرستي اليوم {day} بتاريخ {date}`,
            students: [],
            loading: false,
            view_archive: false,
            classrooms: [],
            selected_classroom: "all",
            selected: "",
            dates: {},
            show: 'all',
            classroom: 'all',
            teachers: [],
            days: {
                0: "الاحد",
                1: "الاثنين",
                2: "الثلاثاء",
                3: "الاربعاء",
                4: "الخميس",
                5: "الجمعة",
                6: "السبت"
            },
        }
    },
    created(){
      var g = this;
      var school_id = g.school_id
      setTimeout(() => {
        $("#datex").html(new Date().toISOString().split('T')[0])
        $("body").append(`
          <textarea class='get-local-storage' name='m_students_${school_id}'></textarea>
        `)
        var i1 = setInterval(() => {
            if($(`.get-local-storage-value[name='m_students_${school_id}']`).length){
              clearInterval(i1)
              g.students = JSON.parse($(`.get-local-storage-value[name='m_students_${school_id}']`).val())
              g.students.forEach(element => {
                if(!g.classrooms.includes(element.classroom)){
                  g.classrooms.push(element.classroom)
                }
              })
              $("body").append(`
                <textarea class='get-local-storage' name='dates'></textarea>
                `)
                var ix = setInterval(() => {
                    if($(`.get-local-storage-value[name='dates']`).length){
                    clearInterval(ix)
                    g.dates = JSON.parse($(`.get-local-storage-value[name='dates']`).val())
                    if(Object.values(g.dates)[0]){
                        document.getElementById("datex").innerHTML = 'تقرير دخول الطلاب لمنصة مدرستي ' + Object.values(g.dates)[0]
                    }
                    }
                }, 10);
            }
        }, 10);
      }, 500);
    },
    methods: {
        excel(){
            const wb = XLSX.utils.book_new(), g = this;
            const Heading = [
                [
                    "م",
                    "رقم الهوية",
                    "الاسم",
                    "اسم المستخدم",
                    "الجوال",
                    "الفصل",
                    "آخر دخول",
                ]
            ];
            var xx = [];
            g.students.forEach(function(a, i){
                if((g.show=='all'||g.show=='1'&&g.dates[a.number]||g.show=='2'&&!g.dates[a.number]) && (g.classroom == 'all' || g.classroom == a.classroom)){
                  xx.push([
                      i+1,
                      a.number,
                      a.name,
                      a.email,
                      a.phone,
                      a.classroom,
                      g.dates[a.number] ?? 'لم يتم الدخول',
                  ])
                }
            })
            
            // creating sheet and adding data from 2nd row of column A.
            // leaving first row to add Heading
            const ws = XLSX.utils.json_to_sheet(xx, { origin: 'A2', skipHeader: true });
                
            // adding heading to the first row of the created sheet.
            // sheet already have contents from above statement.
            XLSX.utils.sheet_add_aoa(ws, Heading, { origin: 'A1' });
                
            // appending sheet with a name
            XLSX.utils.book_append_sheet(wb, ws, 'Records');
            const fileContent = XLSX.writeFile(wb, 'تقرير دخول الطلاب للمنصة' + ".xlsx");
        },
        print(){
            var divToPrint=document.getElementById("headerTable");
            var newWin= window.open("");
            newWin.document.write(divToPrint.outerHTML + `<style>
                body{
                    direction: rtl;
                    text-align: right
                }
                thead{
                    background: #eee;
                }
                table, td, th {
                border: 1px solid;
                }
                tr:nth-child(even) {
                    background-color: #f2f2f2;
                }
                table {
                width: 100%;
                border-collapse: collapse;
                }
                button{
                    display:none
                }
                </style>`);
            newWin.document.close();
            newWin.print();
        },
        send(){
          $("#c-subscribe-ended").click()
          $("#after-send-settings-click").val("#notjoinstudentsalert")
        },
        messageChanged(){
            var g = this, c = [];
            var d = new Date();
            g.students.forEach(function(a){
              if(!g.dates[a.number]){
                a.message = g.message?.replace("{name}", a.name)?.replace("{day}", g.days[d.getDay()])?.replace("{date}", new Date().toISOString().split("T")[0])
                c.push(a)
              }
            })
            g.teachers = c
            if(localStorage.getItem('send')){
                g.selected = JSON.parse(localStorage.getItem('send')).selected
            }
        },
        sendNow(method){
            var g = this;
            g.loading = true
            $.post(api + '/api/messages/send', {
                school_id: localStorage.getItem('school_id'),
                arr: JSON.stringify(g.teachers),
                method: method,
                settings: localStorage.getItem('send'),
                type: 'students'
            })
            .then(function(response){
                g.loading = false
                if(response.status == 100){
                    g.view_archive = true
                }else{
                    alert(response.response)
                }
            })
            .fail(function(){
                g.loading = false
                alert('حدث خطأ')
            })
        },
    }
}
</script>

<style>

</style>